import React, {Component} from "react"
import Layout from "./../../components/layout";
import SEO from "./../../components/seo";
import WorkshopContainer from "./../../components/workshop-container";
import WorkshopData from "./workshop-data.json";
import Workshop from "./shared/workshop";
import {InnerPage, WorkshopGrid, PageWrapper} from "./shared/default-workshop.styles";

class SocialStudiesPage extends Component {
    render() {
        const SocialStudiesWorkshops = WorkshopData.filter(workshop => workshop.allContent);
        return (
            <Layout>
                <SEO title="Mixed Content | s3strategies" />
                <WorkshopContainer title="All Mixed Content" />
                <PageWrapper>
                <InnerPage>
                    <WorkshopGrid>
                        {SocialStudiesWorkshops.map((workshop, index) => (
                           <Workshop workshop={workshop} index={index} />
                        ))}
                    </WorkshopGrid>
                    <br />
                    <br />
                </InnerPage>
                </PageWrapper>
            </Layout>
        )
    }
}
export default SocialStudiesPage;